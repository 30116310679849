<template>
  <div style="min-width: 1200px;">
    <banner-bg :type="4">
      <div class="banner-base">
        <new-banner
          :bannerList="bannerList"
          :height="600"
        ></new-banner>
      </div>
    </banner-bg>
    <div class="container">
      <section class="section1">
        <div class="steam-swiper">
          <div class="steam-gallery">
            <el-carousel
              height="400px"
              type="card"
              indicator-position="none"
            >
              <el-carousel-item
                v-for="(item,key) in section1CarouseImgList"
                :key="key"
              >
                <img
                  class="steam-img"
                  :src="item"
                >
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="media-query-w">
            <img
              src="@/assets/image/curriculum/section1-img1.svg"
              class="title"
            >
          </div>
        </div>
        <section class="section2">
          <div class="media-query-w">
            <div class="item1">
              <img
                src="@/assets/image/curriculum/section2.svg"
                class="m-img"
              >
            </div>
          </div>
        </section>
        <div class="media-query-w">
          <img
            src="@/assets/image/curriculum/section1-img2.svg"
            class="m-img"
          >
        </div>
        <div class="media-query-w">
          <img
            src="@/assets/image/curriculum/section1-img3.svg"
            class="m-img"
          >
        </div>
      </section>

    </div>
  </div>
</template>
<script>
import { getBanners } from '@/api/common';
import section1CarouseImg1 from '@/assets/image/curriculum/section-carouse1.svg';
import section1CarouseImg2 from '@/assets/image/curriculum/section-carouse2.svg';
import section1CarouseImg3 from '@/assets/image/curriculum/section-carouse3.svg';
import BannerBg from '@/components/BannerBg.vue';
import NewBanner from '@/components/NewBanner.vue';
export default {
  components: { BannerBg, NewBanner },
  data() {
    return {
      bannerList: [],
      section1CarouseImgList: [
        section1CarouseImg1,
        section1CarouseImg2,
        section1CarouseImg3,
      ]
    }
  },
  created() {
    let param = {
      available: 1,
      pageSize: 99,
      location: 'kcjs',
      platform: 'yqb',
      isPortal: '1'
    }
    getBanners(param).then(res => {
      if (res.success) {
        this.bannerList = res.result.records;
      } else {
        this.$message.error(res.message);
      }
    })
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/views/curriculum-system.scss';
</style>